/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref } from '@nuxtjs/composition-api';
import axios from 'axios';
import { InternalEndpoints } from '~/enums/internalEndpoints';
import { getListUrlRewritesByTargetPaths } from '~/serverMiddleware/getGeminiData';

const useGeminiApi = () => {
  const geminiData = ref(null) as Record<string, unknown>;
  const loading = ref(false);
  const error = ref(null) as Record<string, unknown>;

  const getGeminiData = async (entityGrn: string) => {
    loading.value = true;
    geminiData.value = null;
    error.value = null;
    try {
      if (!process.client || !process.env.GEMINI_RESOURCE_JWT) {
        geminiData.value = await getListUrlRewritesByTargetPaths(entityGrn);
      } else {
        const response = await axios.get('/api/getgeminidata', {
          params: {
            grn: entityGrn,
            time: Date.now(),
          },
        });
        geminiData.value = response.data;
      }
    } catch (err) {
      error.value = err;
    }
    loading.value = false;
  };

  const validateRecaptcha = async (token: string) => {
    try {
      const {
        data: { success },
        status,
      } = await axios.post(InternalEndpoints.ValidateRecaptcha, {
        token,
      });
      return status === 200 && success;
    } catch (e) {
      console.error('validateRecaptcha ~ error:', e);
    }
    return null;
  };

  return {
    geminiData,
    loading,
    error,
    validateRecaptcha,
    getGeminiData,
  };
};

export default useGeminiApi;
