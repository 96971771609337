/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkoutErrorsMap } from '~/helpers/maps';
import { CartNote } from '~/types/types';
import { UiNotification } from '../useUiNotification';

type SendNotification = (arg0: UiNotification) => void;
type TranslateFunction = (arg0: string) => any;

export const handleCreateCustomerAddressResult = (success: boolean, sendNotification: SendNotification, translateFunction: TranslateFunction) => {
  if (success) {
    sendNotification({
      id: Symbol('create_address_ok'),
      message: `${translateFunction('Address saved successfully.')}`,
      type: 'success',
      icon: 'success',
      persist: false,
      title: `${translateFunction('Success')}`,
    });
  } else {
    sendNotification({
      id: Symbol('cart_error'),
      message: `${translateFunction('Error while saving user address.')}`,
      type: 'danger',
      icon: 'check',
      persist: false,
      title: `${translateFunction(`Error`)}`,
    });
  }
};

/**
 * Shows a notification with the given error message for the given step.
 * @param step - The step where the error occurred.
 * @param key - The key of the error message in the checkoutErrorsMap.
 * @param errorMessage
 */
export const notifyCheckoutError = (
  step: string,
  key: string,
  sendNotification: SendNotification,
  translateFunction: TranslateFunction,
  errorMessage?: string
): void => {
  try {
    const { title, message } = checkoutErrorsMap[step][key];
    sendNotification({
      id: Symbol('checkout_error'),
      message: `${!errorMessage ? translateFunction(message) : translateFunction(errorMessage)}`,
      type: 'danger',
      icon: 'check',
      persist: false,
      title,
    });
  } catch (error) {
    console.error(error);
  }
};

export const checkoutAdditionalDataToCartNotes = (additionalData: Record<string, string>): Array<CartNote> => {
  const notes: Array<CartNote> = [];

  try {
    Object.entries(additionalData).forEach(([key, value]) => {
      notes.push({
        noteKey: key,
        note: value,
      });
    });
    return notes;
  } catch (error) {
    console.error('useCheckout/_helpers ~ checkoutAdditionalDataToCartNotes ~ 1 ~ error:', error);
  }

  try {
    notes.push({
      noteKey: 'additionalData',
      note: JSON.stringify(additionalData),
    });
    return notes;
  } catch (error) {
    console.error('useCheckout/_helpers ~ checkoutAdditionalDataToCartNotes ~ 2 ~ error:', error);
  }

  return notes;
};
