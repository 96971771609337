import { ref, useContext, computed } from '@nuxtjs/composition-api';

const useCms = () => {
  const cmsData = ref(null) as Record<string, unknown>;

  const {
    route: {
      value: { path },
    },
    app: { localePath },
  } = useContext();

  const currentLocale = computed(() => {
    try {
      return path?.split('/')?.[1] || 'it';
    } catch (error) {
      console.error('useCms ~ currentLocale ~ error:', error);
    }
    return 'it';
  });

  const privacyPolicyUrl = computed(() => {
    try {
      return localePath(`/privacy-policy`);
    } catch (error) {
      console.error('useCms ~ privacyPolicyUrl ~ error:', error);
    }
    return '/it/privacy-policy';
  });

  return {
    cmsData,
    privacyPolicyUrl,
  };
};

export default useCms;
