/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */
import { ref } from '@nuxtjs/composition-api';
import seoHelpers from '~/helpers/seo/seoHelpers';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';

const sanificateIncomingData = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      if (['id', 'blockName', 'blockType'].includes(key)) {
        return newObj; // skip these keys
      }
      const newKey = key === 'type' ? '@type' : key;
      newObj[newKey] = sanificateIncomingData(value);
      return newObj;
    },
    Array.isArray(obj) ? [] : {}
  );
};

const useHome = () => {
  const { getAlternates, getCanonical } = seoHelpers();
  const homeMeta = ref({});
  const alternates = ref([]);
  const homeBlocks = ref();
  const { blocks, loadBlocks } = useContent();
  const { locale } = useI18n();

  const getHomeAlternates = async () => {
    try {
      alternates.value = await getAlternates('home', true);
    } catch (e) {
      console.error('Unable to get alternates', e);
    }
  };

  const populateHomeMeta = async () => {
    await getHomeAlternates();
    homeMeta.value = {
      title: 'Homepage - Radio Italia',
      meta: [
        {
          hid: 'og:description',
          property: 'og:description',
          content: `Radio Italia - E-COMMERCE`,
        },
        {
          hid: 'description',
          name: 'description',
          content: `Radio Italia - E-COMMERCE`,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Radio Italia',
        },
      ],
      link: [...alternates.value, getCanonical()],
    };
  };
  const loadHomeBlocks = async () => {
    try {
      await loadBlocks({
        identifiers: [`hero-slider-${locale}`, `products-accordions-${locale}`, `horizontal-card-${locale}`, `tiles-${locale}`],
      });
      const blocksData = await contentGetters.getCmsBlockContent(blocks.value);
      homeBlocks.value = {
        ...(blocksData[`hero-slider-${locale}`] && { heroSlider: blocksData[`hero-slider-${locale}`] }),
        ...(blocksData[`products-accordions-${locale}`] && { productsCarousels: blocksData[`products-accordions-${locale}`] }),
        ...(blocksData[`horizontal-card-${locale}`] && { horizontalCard: blocksData[`horizontal-card-${locale}`] }),
        ...(blocksData[`tiles-${locale}`] && { tiles: blocksData[`tiles-${locale}`] }),
      };
    } catch {
      console.error('Could not fetch home content');
    }
  };

  return {
    loadHomeBlocks,
    populateHomeMeta,
    homeBlocks,
    homeMeta,
  };
};

export default useHome;
